<template>
	<div>
		<div class="price-change-review">
			<div class="price-change-container">
				<div class="user-info">
					<div class="title">客户信息</div>
					<div class="content">
						<div class="user-detail flex-wrap">
							<div>客户名称</div>
							<div class="light-color">{{ pageInfo.customerName }}</div>
						</div>
						<div class="user-phone flex-wrap">
							<div>手机号</div>
							<div class="light-color">
								{{ pageInfo.userPhone }}
							</div>
						</div>
						<div class="user-phone flex-wrap">
							<div>邮箱</div>
							<div class="light-color">
								{{ pageInfo.email }}
							</div>
						</div>
						<div class="user-phone flex-wrap">
							<div>真实姓名</div>
							<div class="light-color">
								{{ pageInfo.userName }}
							</div>
						</div>
					</div>
				</div>
				<div class="order-info">
					<div class="title">
						<span>订单信息</span>
						<span class="order-no">{{ pageInfo.orderNo }}</span>
					</div>
					<div class="content">
						<div
							class="order-info-detail"
							v-for="good in pageInfo.goods"
							:key="good.searchProductId"
						>
							<div class="order-img">
								<img :src="(imgUrl +good.picturePath).replace('{0}', '234x234')" />
							</div>
							<div class="order-detail">
								<div class="order-name">{{ good.productName }}</div>
								<div class="order-norms">{{ good.standard }}</div>
								<div class="order-price">
									<span>¥{{ good.price }}</span>
									<span>x{{ good.amount }}</span>
								</div>
							</div>
						</div>
						<div class="total-price">
							合计应付款：
							<span class="light-price">¥{{ pageInfo.totalPrice }}</span>
						</div>
						<div class="description">
							<div class="desc-container">
								<span class="desc-title">改价说明：</span>
								<span class="desc-info">{{ this.pageInfo.changeDescription || '' }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="apply-info">
					<div class="title">申请信息</div>
					<div class="content">
						<div class="change-price flex-wrap">
							<div>修改总价</div>
							<div class="light-price">¥{{ pageInfo.price }}</div>
						</div>
						<div class="change-price flex-wrap">
							<div>优惠金额</div>
							<div class="light-price">¥{{ pageInfo.discountPrice }}</div>
						</div>
						<div class="apply-name flex-wrap">
							<div>申请人</div>
							<div class="light-color">{{ pageInfo.roleName }}</div>
						</div>
						<div class="apply-time flex-wrap">
							<div>申请时间</div>
							<div class="light-color">{{ applyTime }}</div>
						</div>
						<div class="apply-time flex-wrap">
							<div>审核时间</div>
							<div class="light-color">{{ examineTime }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="go-recod" @click="goRecord">
				查看审核记录
			</div>
			<div class="footer" v-if="pageInfo.status === 2">
				<button class="cancel-btn" @click="() => showDialog(0)">拒绝</button>
				<button class="ok-btn" @click="() => showDialog(1)">同意申请</button>
			</div>
		</div>
		<div v-show="passwordDialog" class="agree-pwd">
			<div class="pwd-padding">
				<div class="dialog-title">
					<img
						class="title-close"
						src="../../assets/icons/close-img.png"
						@click="closeDialog"
					/>
					<div class="title">确认{{type==0 ? '拒绝' : '同意'}}申请?</div>
				</div>
				<div class="inp-pwd">
					<input ref="pwd" v-model="psw" type="password" maxlength="6" />
					<ul class="pwd-wrap" @click="focus">
						<li><i v-if="msgLength > 0"></i></li>
						<li><i v-if="msgLength > 1"></i></li>
						<li><i v-if="msgLength > 2"></i></li>
						<li><i v-if="msgLength > 3"></i></li>
						<li><i v-if="msgLength > 4"></i></li>
						<li><i v-if="msgLength > 5"></i></li>
					</ul>
				<div class="price-btn">
					<div class="btn-cancel" @click="closeDialog">取消</div>
					<div class="btn-ok" @click="postExamine()">确认</div>
				</div>
				</div>
			</div>
		</div>
		<transition name="fades">
			<div v-show="passwordDialog" class="dialog-mantle"></div>
		</transition>
	</div>
</template>
<script>
import baseURL from "@/utils/config";
import {toExamine,getModifyPrice} from "@/api/priceReview.js"
export default {
	data() {
		return {
			pageInfo: {
            },
			passwordDialog: false,
			psw: '', // 密码
			msgLength: 0,
			type: null, // 0 拒绝 1 同意
            imgUrl:baseURL.imgUrl
		};
	},
	computed: {
		applyTime() {
			return this.pageInfo.applicationTime;
		},
		examineTime() {
			return this.pageInfo.examineTime ?this.pageInfo.examineTime : '-'
		},
	},
	async created() {
        const data = await getModifyPrice({ id: this.$route.params.id })
        if (data.resultID === 1200) {
			this.pageInfo = data.data;
		}

	},
	watch: {
		psw(curVal) {
			this.msgLength = curVal.length;
			if (this.msgLength >= 6) {
				this.postExamine();
			}
		},
	},
	methods: {
		showDialog(type) {
			document.body.style.overflow = 'hidden';
			document.body.style.height = '100%';
			this.passwordDialog = true;
			this.focus();
			this.type = type;
			
		},
		closeDialog() {
			document.body.style.overflow = 'auto';
			document.body.style.height = 'auto';
			this.passwordDialog = false;
			this.psw = '';
		},
		focus() {
			this.$refs.pwd.focus();
		},
		async postExamine() {
			let obj = {
				orderNo: this.pageInfo.orderNo,
				type: this.type,
				price: this.pageInfo.price,
				changeDescription: this.pageInfo.changeDescription,
                password:this.psw,
				id:this.$route.params.id
			};
			const data = await toExamine(obj)
				if (data.resultID === 1200) {
					if(obj.type === 0) {
						this.$toast('审核已拒绝')
					} else if(obj.type === 1) {
						this.$toast('审核已同意')
					}
					setTimeout(() => {
						this.$router.push({
							path: '/reviewRecord',
						});
						this.closeDialog();
					}, 1000);
					
				} else {
					this.$toast(data.message);
				}
		},
		goRecord() {
			this.$router.push({
				path: '/reviewRecord'
			})
		}
	},
};
</script>
<style lang="scss" scoped>
.price-change-review {
	padding: 4vw;
    padding-bottom: 80px;
	font-family: Source Han Sans CN, Source Han Sans CN-Medium;
	line-height: 16px;
	font-size: 14px;
	color: #666;
	width: 100vw;
	margin-bottom: 16vw;
	position: relative;
	.go-recod {
		position: absolute;
		top: 16px;
		right: 16px;
		color: royalblue;
	}
	.user-info {
		.title {
			margin-top: 0px;
		}
	}
	.order-info {
		.title {
			display: flex;
			justify-content: space-between;
			.order-no {
				color: #666;
				font-size: 12px;
			}
		}
	}
	.title {
		margin: 5vw 0 3vw;
		font-size: 16px;
		color: #333333;
		font-weight: bold;
	}
	.content {
		background: #fff;
		border-radius: 5px;
		.description {
			margin: 0 4vw;
			padding: 0 0 4vw;
			.desc-container {
				padding: 3vw;
				background: #efefef;
				line-height: 1.5;
				border-radius: 5px;
				.desc-title {
					font-weight: 600;
				}
				.desc-info {
					font-size: 12px;
				}
			}
			
		}
		.flex-wrap {
			display: flex;
			justify-content: space-between;
			padding: 4vw;
		}
		.order-info-detail {
			padding: 4vw;
			display: flex;
			.order-img {
				width: 20vw;
				height: 20vw;
				img {
					width: 100%;
					height: 100%;
					border-radius: 8px;
					background: #c4c4c4;
				}
			}
			.order-detail {
				margin-left: 2vw;
				.order-name {
					width: 62vw;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2; //行数
					-webkit-box-orient: vertical;
				}
				.order-norms {
					margin: 2vw 0;
					color: #999999;
					font-size: 12px;
					line-height: 12px;
				}
				.order-price {
					display: flex;
					justify-content: space-between;
				}
			}
		}
		.total-price {
			padding: 4vw 0;
			margin: 0 4vw;
			border-top: 1px solid #eee;
			text-align: right;
			color: #666666;
		}
	}
	.footer {
		padding: 4vw 0;
        width: 100%;
		position: fixed;
		bottom: 0;
		background: #f3f5f7;
		button {
			width: 44vw;
			height: 12vw;
			border-radius: 29px;
			color: #fff;
			&.cancel-btn {
				background-color: #ff6464;
				font-size: 16px;
			}
			&.ok-btn {
				background-color: #60c24f;
				font-size: 16px;
				margin-left: 3vw;
			}
		}
	}
	.light-color {
		color: #333;
	}
	.light-price {
		color: #ff6464;
		font-size: 16px;
	}
}
.fades-enter-active,
.fades-leave-active {
	transition: all .1s;
}
.fades-enter, .fades-leave-to {
	opacity: 0
}
.fades-leave, .fades-enter-to {
	opacity: 1
}
.dialog-mantle {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 10;
}
.agree-pwd {
	width: 80vw;
	background-color: #fff;
	border-radius: 8px;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
	.pwd-padding {
		padding: 20px 16px;
		.dialog-title {
			position: relative;
			.title-close {
				position: absolute;
				left: 0;
				top: -12px;
				width: 16px;
			}
			.title {
				font-size: 16px;
				color: #333;
				font-weight: bold;
				text-align: center;
				margin-top: 8px;
			}
		}
		.inp-pwd {
			margin: 24px 0 48px;
			padding: 5px;
			height: 13vw;
			input {
				position: absolute;
				z-index: -1;
				left: -100%;
				opacity: 0;
			}
			.pwd-wrap {
				width: 95%;
				height: 11vw;
				margin: 0 auto;
				background: #fff;
				border: 1px solid #999;
				border-radius: 4px;
				display: flex;
                margin-bottom: 12px;
				display: -webkit-box;
				display: -webkit-flex;
				cursor: pointer;
				li {
					list-style-type: none;
					text-align: center;
					line-height: 44px;
					-webkit-box-flex: 1;
					flex: 1;
					-webkit-flex: 1;
					border-right: 1px solid #999;
					&:last-child {
						border-right: 0;
					}
					i {
						height: 10px;
						width: 10px;
						border-radius: 50%;
						background: #000;
						display: inline-block;
					}
				}
			}
		}
	}
}
.price-btn{
	display: flex;
	align-items: center;
	justify-content: space-between;
	.btn-cancel{
	  padding: 9px;
		border-radius: 22px 22px 22px 22px;
		border: 1px solid #90D12E;
		text-align: center;
		flex: 1;
		margin-right: 10px;
	}
	.btn-ok{
		padding: 9px;
		text-align: center;
		border-radius: 22px 22px 22px 22px;
		border: 1px solid #90D12E;
		text-align: center;
		flex: 1;
		background-color: #90D12E;
		color: #fff;
	}
}
</style>
